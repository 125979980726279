/* PAGINATION STARTS */
.pagination{
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination-item{
  list-style: none;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1.2rem 1.6rem;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.4rem;
  color: #A51417;
}

.pagination-item:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-item:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-link{
  text-decoration: none;
  font-size: 1.4rem;
  color: #A51417;
  transition: all 0.3s;
}

.pagination-item:hover{
  color: white;
  background-color: #A51417;
  font-weight: 600;
}

.pagination-item:hover .page-link{
  color: white;
}

.pagination-item-active{
  background-color: #A51417;  
}

.pagination-link-active{
  color: white !important;
  font-weight: 600;
}

/* PAGINATION ENDS */

.flex-gap{
  gap: 1.5rem;
}

@media screen and (max-width: 767px) {
  .flex-gap{
    gap: 0rem;
  }
}

.social-icon:hover {  
  color: #007360 !important;
}

.text-justify{  
  text-align: justify;
}